import React, { useContext } from 'react'
import { SearchContext } from '../context/SearchContext'


const Gracias = () => {

    const {idsReserva,urlTicket} = useContext(SearchContext);

    
    const registrar_en_backoffice = async () => {
        try {
            const response = await fetch( process.env.REACT_APP_API + `/register_purchase`, {
                headers: {
                    "Content-type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "X-Requested-With",                    
                },
                method: 'POST',
                body: JSON.stringify(
                    {
                        "Servicios":[ 
                            {
                                "codigoServicio": "1231231",
                                "items": "lallala"
                            }
                         ]          
                    }
            )});
            const data = await response;    
             
            console.log('GUARDAMOS EN EL BACK: ');
            console.log(data);
                                      
            

        } catch (error) {
            const msg= "Error al guardar en el back: " + error.message;
            alert(msg);
            console.log(msg);
        }
    }

    //registrar_en_backoffice()

    return (
        <div style={{background: "#f5f8fa"}}>
            <div className="container margin_120">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div id="confirm">
                            <div className="icon icon--order-success svg add_bottom_15">
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72">
                                    <g fill="none" stroke="#05d6c2" strokeWidth="2">
                                        <circle cx="36" cy="36" r="35" 
                                        style={{strokeDasharray: "240px, 240px", strokeDashoffset: "480px"}} ></circle>
                                        <path d="M17.417,37.778l9.93,9.909l25.444-25.393" 
                                        style={{strokeDasharray: "50px, 50px", strokeDashoffset: "0px"}}
                                        ></path>
                                    </g>
                                </svg>
                            </div>
                        <h2>¡Muchas Gracias por tu Compra!</h2>
                        <p>En los siguientes links encontraras los boletos.</p>
                        {urlTicket.ida &&                         
                            <a href={urlTicket.ida} rel='noreferrer' target='_blank'>Tramo IDA: Ver eTicket</a>                       
                        }
                        <br></br>
                        {urlTicket.vuelta &&                         
                            <a href={urlTicket.vuelta} rel='noreferrer' target='_blank'>Tramo VUELTA: Ver eTicket</a>                       
                        }

                        <button onClick={registrar_en_backoffice} style={{display:'none'}}> Registrar </button>
                        </div>
                    </div>
                </div>            
            </div>
        </div>
    )
}

export default Gracias